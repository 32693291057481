import client from './requestclient';

const faqs = {
    getFaqs: async () => {
        return await client({URL_PATH: 'fetchfaqs', method: "GET"});
    },
    submitFaq: async (body) => {
        return await client({URL_PATH: 'submitfaq', method: "POST", body})
    }
}

export default faqs;